import { localesColl, transl } from '~/composables/useTranslation'
import { byTransl } from './languages.utils';
import { compareStrings } from '@/utils/array';

export function useLanguages() {

    const filter = reactive<any>({
        localeValues: [],
        topValues: [],
        includeSecondaryLang: false,
        sortByTransl: false,
        sortByDate: false
    })

    const languagesColl = computed(() => {
        let coll = localesColl.value.slice();

        if (!coll || !coll.length) return [];

        //sort according to translation
        if (filter.sortByTransl)
            coll.sort((a, b) => byTransl(a, b, p => p.localization_locale));
        else if (filter.sortByDate)
            coll.sort((a, b) => compareStrings(a, b, p => p._id));
        else
            coll.sort((a, b) => compareStrings(a, b, p => p.localization_name.split(':')[1]));

        //exclude sublanguages
        if (filter.includeSecondaryLang == false)
            coll = coll?.filter((l: any) => l.localization_locale?.length == 2)

        //filter locales
        if (filter.localeValues.length)
            coll = coll?.filter((l: any) => filter.localeValues?.includes(l.localization_locale))

        //put primary values on top of the list
        if (filter.topValues?.length)
            coll = orderTopValues(coll, filter.topValues);

        return coll;
    });

    function orderTopValues(coll: any[], topValues: any[]) {
        //Set get us only uniq values
        const topUniq = [...new Set(topValues)];
        const topLangs = topUniq?.map(l => coll.find(c => c.localization_locale == l));
        const topAvailable = topLangs.filter(l => l);
        const otherLangs = coll.filter(c => !topUniq.includes(c.localization_locale));

        return [...topAvailable, ...otherLangs];
    }

    function setLanguageFilter(filterValue: any) {
        filter.topValues = filterValue.topValues || [];
        filter.localeValues = filterValue.localeValues || [];
        filter.includeSecondaryLang = filterValue.includeSecondaryLang || false;
        filter.sortByDate = filterValue.sortByDate || false;
    }

    function matchLanguage(text: string) {

        if (!text || !text.trim().length)
            return languagesColl.value;

        const matchLang = (s: string) => new RegExp("^" + text, "gi").test(s);

        const matchedItems = languagesColl.value.filter((country: any) => {
            return matchLang(transl(country.localization_name)) ||
                matchLang(country.localization_name_origin) ||
                matchLang(country.localization_name.split(':')[1]) ||
                matchLang(country.localization_locale)
        });

        return matchedItems;
    }

    function findByLocale(locale: string) {
        const lang = localesColl.value.find(lang => lang.localization_locale == locale)

        return lang;
    }

    return {
        matchLanguage,
        setLanguageFilter,
        findByLocale,
        languagesColl,
    }
}
