<script setup>
import { transl, activeLocale } from "@/composables/useTranslation";
import { useLanguages } from "@/composables/useLanguages";

const { languagesColl } = useLanguages();

// const props = defineProps(["modelValue"]);
const emit = defineEmits(["change", "update:modelValue"]);
</script>
<template>
  <v-menu class="v-menu-el" :options="{ origins: 'right top' }">
    <template #activator="{ on }">
      <div @click="on()" class="lang-btn">
          <div class="lang-locale mr-2">{{ activeLocale }}</div>
          <img src="/icons/language-svgrepo-com.svg" class="icon-lang" />
      </div>
    </template>

    <template #default="{ close }">
      <div class="languages-wrapper">
        <div
          class="lang-el py-2 px-4"
          @click="
            emit('change', lang.localization_locale);
            close();
          "
          v-for="lang in languagesColl"
        >
          <div class="lang-en">{{ lang?.localization_name.split(":")[1] }}</div>
          <div class="lang-origin">{{ lang?.localization_name_origin }}</div>
        </div>
      </div>
    </template>
  </v-menu>
</template>
<style scoped>
.icon-lang {
  width: 20px;
}
.languages-wrapper {
  background-color: #fff;
  font-size: 16px;
  max-height: 60vh;
  overflow-y: auto;
}
.lang-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 20px;
  user-select: none;
}
.lang-btn:hover{
  background-color: #eee;
}

.lang-el {
  cursor: pointer;
}
.lang-el:hover {
  background-color: #eee;
}
.lang-locale {
  text-transform: uppercase;
  font-size: 14px;
}
.lang-en {
  color: #000;
  font-size: 18px;
}
.lang-origin {
  color: #989898;
}
.d-flex {
  display: flex;
  align-items: center;
}
</style>
~/composables/useTranslation